<template>
    <div id="i39k4j" class="gjs-row">
            <div class="gjs-cell">
                <div id="ik467g" class="gjs-row">
                    <div id="i3cart" class="gjs-cell">
                        <div id="iykjgo"><b>Contact</b></div>
                        <div id="i7rn02" class="gjs-row">
                            <div id="iwo1is" class="gjs-cell">
                                <RouterLink to="/">
                                <img id="i76fsn"
                                    src="https://www.peppubuild.com/logo.png" />
                                </RouterLink>
                            </div>
                        </div>
                        <a href="https://github.com/hannydevelop/Peppubuild"><img src="../views/img/github.svg" id="ic8w0z" /></a>
                    </div>
                    <div id="izcu45" class="gjs-cell footer-link">
                        <div id="ilfzer"><b>About</b></div>
                        <div id="irf1pp"><a href='#' id="iqafkl">Changelog</a></div>
                        <div id="ispctj"><a href='#' id="iw123g">Careers</a></div>
                    </div>
                    <div id="ip0jb2" class="gjs-cell footer-link">
                        <div id="i2twsw"><b id="iczgjq">Learn</b></div>
                        <div id="iqdwqg"><a href='https://docs.peppubuild.com' id="izaojg">Documentation</a></div>
                        <div id="i805n1"><a href='#' id="ip3i93">Video Tutorial</a></div>
                        <div id="ibix5p"><a href='https://github.com/hannydevelop/Peppubuild' id="iv6ed4">Github
                                Examples</a></div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
  name: 'FootBar'
}
</script>