<template>
    <HeadBar />
    <div id="ievw" class="gjs-row">
        <div id="i9zl" class="gjs-cell">
            <div id="itzz" class="gjs-row">
                <div id="ik0y" class="gjs-cell">
                    <div id="itwl">Create Anything for the Web in Five Minutes.</div>
                    <div id="if7yqy" class="gjs-row">
                        <div id="idn5fk" class="gjs-cell">
                            <div id="irah"><span id="irg1l">If you are concerned about building fast and scaling
                                    your application, you are in the right place.</span><br id="i2fji"
                                    draggable="true" /><span id="iclay">We are doing things different from
                                    traditional No-code tools.</span><br id="i8e68" draggable="true" /><span id="iywoh">With
                                    Peppubuild, you own your code and project.</span></div>
                        </div>
                        <div id="iovt3j" class="gjs-cell"><img id="ibtf31" src='./img/aboutmain.png'>
                        </div>
                    </div>
                    <div id="imq3mz" class="gjs-row">
                        <div id="ihjvdl" class="gjs-cell"><button type="button"
                                onclick="location.href='https://app.peppubuild.com';" id="iijd1h">Start Now</button>
                        </div>
                        <div id="iolmp2" class="gjs-cell"><button type="button"
                                onclick="location.href='https://docs.peppubuild.com';" id="igkwzh">Documentation</button>
                        </div>
                    </div>
                    <div id="ip6wxe" class="gjs-row">
                        <div class="gjs-cell" id="ip3mx"><iframe allow="fullscreen" id="ikjk"
                                src="https://www.youtube.com/embed/MYQjBkKnNUM?"></iframe></div>
                    </div>
                </div>
            </div>
            <div id="inm6u" class="gjs-row">
                <div class="gjs-cell">
                    <div id="iltcq" class="gjs-row">
                        <div id="i552d" class="gjs-cell"><img id="i1zer" src="./img/build.png" /> </div>
                        <div id="iz5k8" class="gjs-cell">
                            <div id="ioeaj"><span id="id0hg"><b>Vendor lock-in, Reliability, and
                                        Scalability.</b></span></div>
                            <div id="i2f2k">Traditional No-code tools are designed to deprive you of the reliability
                                and scalability you need. First, they make it difficult for you to switch because
                                they either have your codes or your application is built using tools that they only
                                can offer. However, with Peppubuild you have full control of your project artifacts.
                                Take the step, be in charge!</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container col-xxl-12 px-4 py-5">
                <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div class="col-10 col-sm-8 col-lg-7 himg">
                        <img src="./img/peppu-dnd.gif" alt="" class="d-block mx-lg-auto img-fluid imgb" />
                    </div>
                    <div class="col-lg-5">
                        <h1 class="display-5 fw-bold text-wh lh-1 mb-3">Drag and drop page builder
                        </h1>
                        <p class="lead text-wh">Quickly design and customize responsive websites with Peppubuild's
                            page builder. Get real WYSIWYG visual editor, with final export exactly as editor's
                            replica.
                        </p>
                    </div>
                </div>
            </div>
            <div id="iejhii">Total web management</div>
            <div id="inm6u" class="gjs-row">
                <div class="gjs-cell">
                    <div id="iltcq" class="gjs-row">
                        <div id="iz5k8" class="gjs-cell">
                            <div id="ioeaj"><span id="id0hg"><b>All your projects in one place.</b></span></div>
                            <div id="i2f2k">Control all of your websites in one place, from your dashboard. Utilize
                                our traffic tracking and SEO optimization tools, all available from your dashboard.
                                Explore third-party outsourcing with our role-based access control, so your clients
                                can update their websites with ease.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="iejhii">Learn and build</div>
            <div class="container col-xxl-12 px-4 py-5">
                <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div class="col-10 col-sm-8 col-lg-7 himg">
                        <img src="./img/peppu-drop.png" alt="" class="d-block mx-lg-auto img-fluid imgb" />
                    </div>
                    <div class="col-lg-5">
                        <h1 class="display-5 fw-bold text-wh lh-1 mb-3">Education for All
                        </h1>
                        <p class="lead text-wh"><a class="lead text-wh"
                                href="https://uis.unesco.org/en/topic/education-africa">According to UNESCO,</a>
                            sub-Saharan Africa has
                            the highest rates of education exclusion of all regions. At Peppubuild,
                            we are committed to contribute in reducing education exclusion in all parts of the
                            world. Hence, our website for beginners curriculum is free for
                            use, for all Non-profit and NGO striving towards better education.
                        </p>
                    </div>
                </div>
            </div>
            <div id="iejhii">Our Offer?</div>
            <div id="ijptj5" class="gjs-row">
                <div class="gjs-cell">
                    <div id="iddvn" class="gjs-row">
                        <div id="ifq4hb" class="gjs-cell"><img src="./img/check.png" id="iqmhge" /></div>
                        <div id="iis31n" class="gjs-cell">
                            <div id="im6dti"><span id="icta8q"><b>Ownership</b></span></div>
                            <div id="i1gw3m">Own and retrieve your code as you build. When you create a new project,
                                all changes you make are automatically stored in your local system when you hit the
                                save button.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="iqk2lg" class="gjs-row">
                <div class="gjs-cell">
                    <div class="gjs-row">
                        <div id="in5qrh" class="gjs-cell"><img src="./img/check.png" id="igw8rd" /></div>
                        <div id="idyztd" class="gjs-cell">
                            <div id="iolnvk"><span id="inmvnl"><b>Flexibility.</b></span></div>
                            <div id="iuxfa1">You are not limited to Peppubuild's editor. If you ever wish to edit
                                changes in traditional editors like "VSCode", Peppubuild offers this. You can switch
                                between Peppubuild and traditional editors while having your changes in-tact.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="ixjisk" class="gjs-row">
                <div id="ixsep" class="gjs-cell">
                    <div class="gjs-row">
                        <div id="ih2o79" class="gjs-cell"><img src="./img/check.png" id="ikbehk" /></div>
                        <div id="ikt27t" class="gjs-cell">
                            <div id="i2ijrb"><span id="i7n0w1"><b>Reliability, and Scalability.</b></span></div>
                            <div id="igviej">Build using frameworks and tools you're already familiar with. We don't
                                have our own technologies; instead we utilise tools already available.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="iw827r" class="gjs-row">
                <div class="gjs-cell">
                    <div class="gjs-row">
                        <div id="i9e0fg" class="gjs-cell"><img src="./img/check.png" id="i96zyw" /></div>
                        <div id="ij6mqd" class="gjs-cell">
                            <div id="ithndc"><span id="ipqs2g"><b>Build Faster</b></span></div>
                            <div id="ih225p">With our drag-and-drop feature, you can build web applications faster.
                                You also have the option to build applications with already saved components.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="i1dy7u">Join The Community</div>
            <div id="ixemdf" class="gjs-row">
                <div id="i8wix5" class="gjs-cell">
                    <div id="io0f1v" class="gjs-row">
                        <div id="ikvhgt" class="gjs-cell">
                            <div id="iu2c7g">Join thousands of users who believe no-code tools should be scalable.
                            </div>
                        </div>
                        <div id="isyjva" class="gjs-cell"><img src="./img/evidence.png" id="iv18x6" /></div>
                    </div>
                </div>
            </div>
            <div id="i1i2ii" class="gjs-row">
                <div id="ie6jx7" class="gjs-cell">
                    <div id="iu0iby" class="gjs-row">
                        <div id="ix59ws" class="gjs-cell"><button onclick="location.href='https://discord.gg/69fCAVPQ';"
                                type="button" id="il2f4y">Discord</button></div>
                        <div id="i5c9ob" class="gjs-cell"><button
                                onclick="location.href='https://github.com/hannydevelop/Peppubuild/discussions';"
                                type="button" id="i7q442">Github
                                Discussion</button></div>
                    </div>
                    <div class="gjs-row" id="ifxq72">
                        <div class="gjs-cell" id="ivi0uw">
                            <div id="idft2n">Try Peppubuild online if you're in a hurry...</div>
                            <div class="gjs-row" id="ix3aki">
                                <div class="gjs-cell" id="i1ka2z"><button
                                        onclick="location.href='https://app.peppubuild.com/';" type="button" id="ibxomr">Try
                                        Now!</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <FootBar />
    </div>
</template>

<style>
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css');
@import './css/style.css';

</style>

<script>
import HeadBar from '../components/HeadBar.vue';
import FootBar from '../components/FootBar.vue';


export default {
  name: 'HomePage',

  components: { HeadBar, FootBar },
}

</script>