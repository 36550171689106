<template>
    <HeadBar />
    <div id="ik0y" class="gjs-cell">
        <div id="itwn">Pricing for Peppubuild</div>
    </div>
    <div class="container col-sm-8 px-4 py-5">
        <h2 class="pb-2 border-bottom">Plan Overview
        </h2>
        <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
            <div class="col">
                <div class="card mb-4 rounded-3 shadow-sm">
                    <div class="card-header py-3">
                        <h4 class="my-0 fw-normal">Community
                        </h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">$0
                            <small class="text-body-secondary fw-light">/mo</small>
                        </h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>Includes:
                            </li>
                            <li>Educational development
                            </li>
                            <li>Curriculum for development
                            </li>
                            <li>Learn as you build
                            </li>
                        </ul>
                        <button type="button" class="w-100 btn btn-outline-success">Sign up for free</button>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4 rounded-3 shadow-sm">
                    <div class="card-header py-3">
                        <h4 class="my-0 fw-normal">Basic
                        </h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">$5
                            <small class="text-body-secondary fw-light">/mo</small>
                        </h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>Everything in community and:
                            </li>
                            <li>Artifacts export
                            </li>
                            <li>Hosting
                            </li>
                            <li>SEO integration
                            </li>
                        </ul>
                        <button type="button" class="w-100 btn btn-outline-success">Sign up for free</button>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4 rounded-3 shadow-sm">
                    <div class="card-header py-3">
                        <h4 class="my-0 fw-normal">Custom
                        </h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">Custom
                        </h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>Everything in basic and:
                            </li>
                            <li>Custom storage
                            </li>
                            <li>Total web management
                            </li>
                            <li>Enterprise package
                            </li>
                        </ul>
                        <button type="button" class="w-100 btn btn-outline-success">Sign up for free</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container alert alert-success col-sm-8 px-4 py-5" role="alert">
        <h4 class="alert-heading">Education for all!</h4>
        <p>According to UNESCO, sub-Saharan Africa has
            the highest rates of education exclusion of all regions. At Peppubuild,
            we are committed to contribute in reducing education exclusion. Hence, our educational development version
            is free for
            use, for all Non-profit and NGO striving towards better education.
        </p>
        <hr>
        <p class="mb-0">Send us a mail @support.peppubuild.com, if you would like to partner with us or request an
            instructor.</p>
    </div>

    <div class="container">
        <h2 class="plans">Compare Plans</h2>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">General / Pricing</th>
                    <th scope="col">Community</th>
                    <th scope="col">Basic</th>
                    <th scope="col">Custom</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">Spaces (Project) included</th>
                    <td>-</td>
                    <td>10</td>
                    <td>Unlimited</td>
                </tr>
                <tr>
                    <th scope="row">Maximum users included</th>
                    <td>1</td>
                    <td>4</td>
                    <td>Unlimited</td>
                </tr>
                <tr>
                    <th scope="row">Uptime SLA</th>
                    <td>99%</td>
                    <td>99%</td>
                    <td>99%</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <FootBar />
    </div>
</template>

<style>
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css');
@import './css/style.css';

</style>

<script>
import HeadBar from '../components/HeadBar.vue';
import FootBar from '../components/FootBar.vue';


export default {
  name: 'PricingPage',

  components: { HeadBar, FootBar },
}

</script>